<template>
  <v-card class="elevation-6 pa-3 login-card">
    <div class="layout column align-center">
      <img src="/static/light.png" alt="Safemod" height="50" />
    </div>
    <v-spacer class="mt-5"></v-spacer>
    <v-card-text>
      <v-form v-model="loginForm">
        <v-text-field
          append-icon="person"
          name="email"
          label="Email"
          type="text"
          v-model="model.email"
          :rules="[rules.required]"
        ></v-text-field>
        <v-text-field
          append-icon="lock"
          name="password"
          label="Senha"
          id="password"
          type="password"
          v-model="model.password"
          :rules="[rules.required]"
        ></v-text-field>
      </v-form>
      <div class="login-btn">
        <v-spacer></v-spacer>
        <v-btn
          block
          color="primary"
          :disabled="!loginForm"
          @click="login"
          :loading="loading"
          >Login</v-btn
        >
      </div>
      <p style="text-align: center">
        Esqueceu a senha?<a
          @click="$router.push({ path: 'reset' })"
          class="register_link"
        >
          Trocar</a
        >
      </p>
      <v-alert v-model="error" transition="scale-transition" type="error">
        Credenciais Inválidas
      </v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  components: {},
  data: () => ({
    isCropImageDialogVisible: false,
    loginForm: true,
    loading: false,
    valid: true,
    errorModel: {},
    model: {
      email: '',
      password: ''
    },
    error: false,
    rules: {
      required: (value) => !!value || 'Campo Obrigatório',
      email: (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || 'E-mail Inválido';
      }
    }
  }),

  async mounted() {
    await this._handleVersion();
  },
  methods: {
    ...mapActions({
      auth: 'login',
      _handleVersion: 'handleVersion'
    }),
    async login() {
      try {
        this.loading = true;
        await this.auth(this.model);
        await this.$router.push(
          this.$route.query.redirect
            ? this.$route.query.redirect.toString()
            : '/'
        );
        this.loading = false;
        this.error = false;
      } catch (e) {
        this.errorModel.message =
          'Não existe conta associada a esse endereço de e-mail ou a senha é inválida.';
        this.loading = false;
        this.error = true;
      }
    },
    redirectToLogin() {
      this.isRegister = false;
    }
  }
};
</script>
<style scoped lang="css"></style>
